import { money } from "../form/formatters";

const InfoCard = ({ job }) => {
  const percentageValue = () => {
    if (job.estimated_value && job.completed_value) {
      return ((job.completed_value / job.estimated_value) * 100).toFixed(2);
    }
    return 0;
  };

  return (
    <div className="card card-status mt-3">
      <label className="slim-card-title">Value</label>
      <div className="flex-grow-1">
        <div className="media-body">
          <div className="d-flex tx-inverse">
            <p className="me-auto">Estimated/Actual:</p>
            <p>
              {money.format(job.estimated_value)} /{" "}
              {money.format(job.completed_value)}
            </p>
          </div>
          <div className="d-flex tx-inverse mt-3 mb-4">
            <p className="me-auto">Percentage Claimed:</p>
            <p>% {percentageValue()}</p>
          </div>
        </div>
      </div>
      <label className="slim-card-title mt-4 ">Time (Hours)</label>
      <div className="flex-grow-1">
        {/*  */}
        <div className="media-body">
          <div className="d-flex tx-inverse">
            <p className="me-auto">Estimated/Actual:</p>
            <p>
              {job.estimated_hours
                ? parseFloat(job.estimated_hours)?.toFixed(1)
                : "0.0"}{" "}
              / {job.total_hours_added?.toFixed(1)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
