import { ContentState, convertFromRaw, convertToRaw } from "draft-js";
import dayjs from "dayjs";

export const date = {
  format: (value) => {
    if (!value) {
      return;
    }

    return dayjs(value).format("DD/MM/YYYY");
  },
  normalize: (value) => {
    if (
      value &&
      value !== "Invalid Date" &&
      dayjs(value).isAfter("2000-01-01T00:01", "year")
    ) {
      return dayjs(value).format("YYYY-MM-DD");
    } else {
      return null;
    }
  },
  timeFormat: false,
};

export const dateTimeLocal = {
  format: (value) => {
    if (
      value &&
      value !== "Invalid Date" &&
      dayjs(value).isAfter("2000-01-01T00:01", "year")
    ) {
      return dayjs(value).format("YYYY-MM-DDTHH:mm");
    }
  },

  normalize: (value) => {
    if (
      value &&
      value !== "Invalid Date" &&
      dayjs(value).isAfter("2000-01-01T00:01", "year")
    ) {
      return dayjs(value).format("YYYY-MM-DDTHH:mm");
    }
  },
};

export const money = {
  format: (value) => {
    if (value === undefined || value === null) {
      return;
    }
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  normalize: (value) => value.replace(/\$\s?|(,*)/g, ""),
};

export const bsb = {
  format: (value) => {
    if (value === undefined || value === null) {
      return;
    }

    return value.replace(/(\d{3})(\d{3})/, "$1-$2").replace(/\s/g, "");
  },
  normalize: (value) => value.replace("-", ""),
};

export const percentage = {
  format: (value) => {
    if (!value && value !== 0) {
      return;
    }

    const formattedValue = typeof value === "number" ? value.toFixed(2) : value;

    return `${formattedValue}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  normalize: (value) => value.replace(/\%\s?|(,*)/g, ""),
};

export const draftField = {
  format: (value) => {
    if (!value) {
      return;
    }

    return convertFromRaw(value);
  },
  normalize: (value) => {
    if (!value) {
      convertToRaw(ContentState.createFromText(""));
    }

    return convertToRaw(value.getCurrentContent());
  },
};
