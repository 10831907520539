import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useProjectPurchases from "./hooks/useProjectPurchases";
import ResourcePurchaseList from "./ResourcePurchaseList";
import PurchaseButtons from "./PurchaseButtons";
import { purchaseFilters } from "./PurchasesList";
import DocsModal from "../utils/DocsModal";
import { useState } from "react";

const ProjectPurchases = (props) => {
  const { number } = useParams();

  const [purchaseModal, setPurchaseModal] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState();
  const [url, setUrl] = useState("");

  const toggleModal = (newUrl) => {
    setPurchaseModal(!purchaseModal);
    if (selectedPurchase) {
      setSelectedPurchase(undefined);
    }
    setUrl(newUrl);
  };

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/purchasing/" />
      <PurchaseButtons
        {...props}
        toggleModal={toggleModal}
        url={url}
        setUrl={setUrl}
        purchaseModal={purchaseModal}
        setPurchaseModal={setPurchaseModal}
        selectedPurchase={selectedPurchase}
      />
      <PaginatedList
        listName="projectPurchasesList"
        indexHook={useProjectPurchases}
        indexHookArguments={[number]}
        originalFilters={purchaseFilters}
        list={({ data }) => {
          return (
            <div className="col-12">
              <ResourcePurchaseList
                purchases={data ?? []}
                setSelectedPurchase={setSelectedPurchase}
                toggleModal={toggleModal}
                project={props.project}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default ProjectPurchases;
