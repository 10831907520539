export enum ProjectStatus {
  OPEN,
  CLOSED,
}

export interface ProjectIndex {
  id: number;
  uuid: string;
  name: string;
  number: string;
  client?: {
    name: string;
    link: string;
  };
  manager: {
    name: string;
    email: string;
  };
  industry: string;
  status: 0 | 1;
  internal: boolean;
  location?: string;
  latitude: number;
  longitude: number;
  is_template: boolean;
}

export interface IProject {
  name: string;
  number: string;
  full_name: string;
  organisation_id: number;
  organisation: {
    name: string;
  };
  manager?: {
    name: string;
  };
  description: string;
  country: string;
  state: string;
  postcode: string;
  suburb: string;
  line_1: string;
  line_2: string;
  client: {
    display_name: string;
    hold: boolean;
    uuid: string;
  };
  link: string;
  scheduled_start_date: string;
  scheduled_finish_date: string;
  actual_start_date: string;
  actual_finish_date: string;
  client_id: number;
  project_manager: number;
  industry_id: number;
  uuid: string;
  id: number;
  status: number;
  purchase_order?: string;
  estimated_value: number;
  realised_value: number;
  percentage_completed: number;
  formatted_address: string;
  tender?: {
    name: string;
    job: string;
    link: string;
  };
  is_confidential: boolean;
  care_of?: string;
  total_overdue: number;
  total_unpaid: number;
  deliverable_recipients?: any[];
  latitude: number;
  longitude: number;
  boundaries?: {
    latitude: number;
    longitude: number;
    radius: number;
  };
  internal: boolean;
  jobs_count: number;
  is_template: boolean;
  inter_entity_invoices: {
    name: string;
    uuid: string;
    link: string;
    id: number;
  }[];
  team: {
    id: number;
    name: string;
    email: string;
    label: string;
    value: number;
  }[];
}

export interface ProjectContact {
  name: string;
  uuid: string;
  contact_id: number;
  phone?: string;
  email?: string;
  id: number;
  deliverable_recipient: boolean;
  description: string;
  feedback: {
    created_at: string;
    completed_at?: string;
  }[];
}

export interface ProjectAllowance {
  name: string;
  uuid?: string;
  id?: string;
  unit: ProjectAllowanceEnum;
  amount: number;
  unit_description: string;
}

export enum ProjectAllowanceEnum {
  Hours,
  Day,
  Kilometre,
}
