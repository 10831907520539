import dayjs from "dayjs";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { destroyForm } from "../../actions/utilActions";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import SyncIntegrationEstimation from "../purchases/SyncIntegrationEstimation";
import CostWidget from "../utils/CostWidget";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import Edit from "./Edit";
import FilePage from "./FilePage";
import MarkAsBilledButton from "./MarkAsBilledButton";
import { CreditCard } from "../userCreditCards/UserCreditCardsList";
import { money } from "../form/formatters";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import { GroupType } from "../user/userTypes";
import { IntegrationType } from "../integrations/integrationTypes";
import { useAuth } from "../../context/auth-context";
import PrePaymentPage from "../purchases/PrePaymentPage";
import { useDropzone } from "react-dropzone";
import UploadOverlay from "./UploadOverlay";
import useS3Uploader from "../hooks/useS3Uploader";
import UploadProgress from "./UploadProgress";

const ShowPage = (props: any) => {
  const { receipt, setReceipt, noUploadReceipt } = props;
  const { page } = useParams<{ page: string }>();

  switch (page) {
    case "details":
      return (
        <>
          {!receipt.integration && <MarkAsBilledButton {...props} />}
          <Edit {...props} initialValues={receipt} />
        </>
      );
    case "file":
      return <FilePage {...props} />;
    case "pre-payments":
      return <PrePaymentPage purchase={receipt} onSuccess={setReceipt} />;
    default:
      return null;
  }
};

const Profile = (props: any) => {
  useEffect(() => {
    return () => {
      props.destroyForm("EditCreditCardReceipt");
    };
  }, []);

  const { uuid, page } = useParams<{ uuid: string; page: string }>();
  const { user } = useAuth();

  const { takeAction, loading: requestingExemption } = useApi();

  const {
    data: receipt,
    setData: setReceipt,
    loading,
    setUrl,
  } = useApi(`credit-card-receipts/${uuid}`, null, true, "/home");

  useEffect(() => {
    setUrl(`credit-card-receipts/${uuid}`);
  }, [uuid]);

  const {
    upload,
    uploadPercent,
    setUploadPercent,
    loading: fileUploading,
  } = useS3Uploader();

  const onDrop = (acceptedFiles: File[]) => {
    return upload(
      acceptedFiles[0],
      `organisations/${user?.active_organisation.uuid}/credit-card-receipts/${receipt.uuid}`,
      (documents: any) => {
        const data = {
          documentable_type: "App\\Models\\Purchase",
          documentable_id: receipt.uuid,
        };

        return takeAction("store", "documents/standard/single", {
          ...data,
          documents: [documents],
        })
          .then((res: any) => {
            toast.success("Document uploaded successfully");
            return res;
          })
          .then((res: any) => {
            setUploadPercent(0);
            setReceipt({
              ...receipt,
              file: res.data.data,
            });
          })
          .catch(errorSwal);
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: receipt?.file.link,
    preventDropOnDocument: false,
  });

  if (loading) return <Spinner loading />;

  const crumbs = [{ link: "", name: receipt?.name, active: true }];

  if (receipt?.project?.name) {
    crumbs.unshift({
      link: `projects/${receipt.project.uuid}/purchases`,
      name: `${receipt.project.name} Purchases`,
      active: false,
    });
  }

  return (
    <>
      <HeaderPage titlePage={receipt.name} crumbs={crumbs} />
      <div className="row row-sm">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{receipt.name}</DashboardH3>
            <DashboardSubTitle>
              {receipt.date
                ? dayjs(receipt.date).format("DD/MM/YYYY")
                : "No date added"}
            </DashboardSubTitle>
            <DashboardSubTitle>
              <a href={`mailto:${receipt.user.email}`}>{receipt.user.name}</a>
            </DashboardSubTitle>
            <DashboardSubTitle>
              {receipt.includes_gst_description}
            </DashboardSubTitle>
            <DashboardSubTitle>
              {receipt.imported_cost ? (
                <>Imported Cost: {money.format(receipt.imported_cost)}</>
              ) : null}
            </DashboardSubTitle>
          </DashboardHeader>
          {!receipt?.file.link &&
            !receipt?.is_approved &&
            !receipt?.approval_action && (
              <div className="mb-4 pos-relative" {...getRootProps()}>
                <UploadOverlay isDragActive={isDragActive} />
                <input {...getInputProps()} />

                <InformationAlert
                  title="No File Uploaded"
                  pushContentRight={true}
                  body={
                    <>
                      <i
                        className="fa fa-cloud-upload ht-115 pos-absolute l-20 r-20 t-20"
                        style={{
                          color: "#002b49",
                          fontSize: "4rem",
                          width: "64px",
                        }}
                      />
                      <div>
                        Drag 'n' drop your file here to upload a copy of the
                        receipt, or request exemption below.
                      </div>
                    </>
                  }
                  type="warning"
                />
              </div>
            )}
          <div className="d-lg-none mb-3">
            <CostWidget
              price={receipt.total_cost}
              name={receipt.name}
              description="Please ensure this matches the total amount on the receipt"
            />
          </div>

          <SyncIntegrationEstimation
            purchase={receipt}
            setPurchase={setReceipt}
          />
          <UploadProgress
            uploadPercent={uploadPercent}
            loading={fileUploading}
          />
          <ShowPage setReceipt={setReceipt} receipt={receipt} {...props} />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/credit-card-receipts/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/credit-card-receipts/${uuid}/file`}
              active={page === "file"}
              icon="icon ion-clipboard text-capitalize"
            >
              File
            </Tab>
            {(user?.groups.includes(GroupType.Accounting) || user?.is_admin) &&
              user?.active_organisation.integration_types.includes(
                IntegrationType.Accounting,
              ) && (
                <Tab
                  link={`/credit-card-receipts/${uuid}/pre-payments`}
                  active={page === "pre-payments"}
                  icon="icon ion-clipboard text-capitalize"
                >
                  Pre Payments
                </Tab>
              )}
          </TabList>
          <div className="d-none mt-3 d-lg-block">
            <CostWidget
              price={receipt.total_cost}
              name={receipt.name}
              description="Please ensure this matches the total amount on the receipt."
            />
          </div>
          <ApprovalButton
            approvalOptions={[
              { value: 1, label: "Approve" },
              { value: 3, label: "Deny" },
            ]}
            approvalAction={receipt.approval_action}
          />
          <div className="mt-3">
            <ApprovalComments
              modelType="App\Models\Purchase"
              modelId={receipt.id}
              className=""
            />
          </div>
          {receipt.estimated_card && (
            <div className="mt-3 w-100">
              <CreditCard
                wrapperClass=""
                card={receipt.estimated_card}
                canUpdate={false}
              />
            </div>
          )}
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Purchase"
              commentableId={receipt.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { destroyForm })(Profile);
