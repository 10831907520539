import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import {
  ResourceGroup,
  ResourceGroupMember,
} from "../resourceGroups/resourceGroupTypes";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import FormHeader from "../utils/FormHeader";
import AddJobFolder from "./AddJobFolder";
import { toast } from "react-toastify";
import DeleteJobFolder from "./DeleteJobFolder";

const ProjectJobFolders = ({ project }: { project: any }) => {
  const { jobFolderUuid } = useParams<{ jobFolderUuid?: string }>();
  const { takeAction }: IUseApi = useApi();

  const [selected, setSelected] = useState<ResourceGroupMember[]>([]);

  const location = useLocation();

  const history = useHistory();

  const {
    setUrl,
    data,
    setData,
    loading,
    refreshData,
  }: IUseApi<ResourceGroup> = useApi("", [], true);

  useEffect(() => {
    setSelected([]);
    if (jobFolderUuid) {
      setUrl(`resource-groups/${jobFolderUuid}`);
      return;
    }

    setUrl(
      `resource-groups?parentId=${project.id}&parentType=${encodeURIComponent(
        "App\\Models\\Project",
      )}`,
    );
  }, [jobFolderUuid]);

  const sortedMembers = data?.members
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

  const orderedDocuments = _.orderBy(
    sortedMembers,
    (member) => member.type === "App\\Models\\ResourceGroup",
    ["desc"],
  );

  const dragImage = new Image();
  dragImage.src =
    "data:image/svg+xml;base64," +
    btoa(`<svg height="40" width="40" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#c5c5c5" />
    <text
      fill="#ffffff"
      fontSize="10"
      textAnchor="middle"
      x="7"
      y="15"
    >
      ${selected.length}
    </text>
  </svg>`);

  const dragProps = (member: ResourceGroupMember) => ({
    draggable: true,
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragStart: (e: React.DragEvent<HTMLDivElement>) => {
      if (!selected.includes(member)) {
        e.preventDefault();
      }

      const size = selected.length > 10 ? 40 : 20;
      e.dataTransfer.setDragImage(dragImage, size, size);
    },
    onDrop: () => {
      const isFolder = member.type === "App\\Models\\ResourceGroup";
      if (isFolder && selected.includes(member)) {
        toast.warning("Unable to move folder into itself");
        return;
      }

      if (isFolder) {
        takeAction("store", `moved-resource-groups`, {
          parent_id: member.member_id ?? member.id,
          members: selected.map((member) => {
            return {
              member_type: member.type,
              member_id: member.member_id ?? member.id,
            };
          }),
        })
          .then(() => {
            const castedData = data as ResourceGroup;

            setData({
              ...castedData,
              members: castedData.members.filter((m) => !selected.includes(m)),
            });
            toast.success("Documents Moved");
            setSelected([]);
            refreshData();
          })
          .catch(errorSwal);
      }
    },
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.ctrlKey) {
        e.preventDefault();
        setSelected(_.xor(selected, [member]));
        return;
      }

      if (e.shiftKey) {
        e.preventDefault();

        const lastClicked = selected[selected.length - 1];
        if (!lastClicked) {
          setSelected(_.isEqual(selected, [member]) ? [] : [member]);
          return;
        }

        const clickPos = orderedDocuments.findIndex(
          (d) => d.uuid === member.uuid,
        );
        const lastPos = orderedDocuments.findIndex(
          (d) => d.uuid === lastClicked.uuid,
        );

        let positive = true;

        if (lastPos > clickPos) {
          positive = false;
        }

        let toSet = [];
        for (
          let i = lastPos;
          positive ? i < clickPos + 1 : i > clickPos - 1;
          positive ? i++ : i--
        ) {
          toSet.push(orderedDocuments[i]);
        }

        setSelected(toSet);

        return;
      }

      setSelected(_.isEqual(selected, [member]) ? [] : [member]);
    },
    onDoubleClick: () => {
      if (member.type === "App\\Models\\ResourceGroup") {
        history.push(
          !jobFolderUuid
            ? `${location.pathname}/${member.uuid}`
            : getNewPath(location.pathname, member.member_uuid),
        );
        return;
      }

      history.push(member.link);
    },
  });

  const folders = orderedDocuments.filter(
    (member) => member.type === "App\\Models\\ResourceGroup",
  );

  const jobs = orderedDocuments.filter(
    (member) => member.type !== "App\\Models\\ResourceGroup",
  );

  if (loading) {
    return (
      <CustomScaleLoader>
        <span style={{ userSelect: "none" }}>Fetching Folders...</span>
      </CustomScaleLoader>
    );
  }

  return (
    <>
      <AddJobFolder project={project} group={data} setGroup={setData} />

      <ol className="breadcrumb slim-breadcrumb mt-3">
        <li
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={() => {
            takeAction("store", "home-resource-groups", {
              parent_type: "App\\Models\\Project",
              parent_id: project.id,
              resource_type: "App\\Models\\ProjectJob",
              members: selected.map((member) => {
                return {
                  type: member.type,
                  id: member.id,
                };
              }),
            })
              .then(() => {
                const castedData = data as ResourceGroup;

                setData({
                  ...castedData,
                  members: castedData.members.filter(
                    (m) => !selected.includes(m),
                  ),
                });
                toast.success("Documents Moved");
                setSelected([]);
                refreshData();
              })
              .catch(errorSwal);
          }}
          className="breadcrumb-item"
        >
          <Link id="home-documents-url" to={getNewPath(location.pathname)}>
            Home
          </Link>
        </li>
        {data?.parents && (
          <>
            {data?.parents.map((parent, index) => {
              return (
                <li
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={() => {
                    takeAction("store", `moved-resource-groups`, {
                      parent_id: parent.id,
                      members: selected.map((member) => {
                        return {
                          member_type: member.type,
                          member_id: member.member_id,
                        };
                      }),
                    })
                      .then(() => {
                        const castedData = data as ResourceGroup;

                        setData({
                          ...castedData,
                          members: castedData.members.filter(
                            (m) => !selected.includes(m),
                          ),
                        });
                        toast.success("Documents Moved");
                        setSelected([]);
                      })
                      .catch(errorSwal);
                  }}
                  className="breadcrumb-item"
                >
                  <Link to={getNewPath(location.pathname, parent.uuid)}>
                    {parent.name}
                  </Link>
                </li>
              );
            })}
            <li className="breadcrumb-item">{data?.name}</li>
          </>
        )}
      </ol>

      <div className="row mt-3">
        <FormHeader>Folders</FormHeader>
        {folders.length === 0 ? (
          <div className="col-12">
            <EmptyFolders name={data?.name ?? project.name} />
          </div>
        ) : (
          folders.map((folder) => {
            return (
              <div className="col-lg-3 mb-4">
                <div
                  className={`${
                    selected.includes(folder) ? "bg-gray-200" : "bg-white"
                  } p-3 tn-300 border rounded-lg shadow-sm d-flex items-center justify-content-center pointer-hover flex-column position-relative`}
                  {...dragProps(folder)}
                >
                  {folder?.members_count === 0 ? (
                    <div
                      className="position-absolute"
                      style={{ top: "10px", right: "10px" }}
                    >
                      <DeleteJobFolder
                        data={data}
                        setData={setData}
                        uuid={folder.member_uuid}
                      />
                    </div>
                  ) : null}
                  <img src="/img/files_single.svg" height="100" />
                  <p
                    className="mb-0 tx-inverse text-center mt-2"
                    style={{ fontWeight: 500 }}
                  >
                    {folder.name}
                  </p>

                  <p className="mb-0 tx-12 text-secondary text-center">
                    Includes{" "}
                    <span className="fw-bolder tx-inverse">
                      {folder.members_count ?? 0}
                    </span>{" "}
                    resources.
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="row ">
        <FormHeader>Jobs</FormHeader>
      </div>
      {jobs.length === 0 ? (
        <div className="d-flex flex-wrap w-100 justify-content-center bg-white p-5 border">
          <img className="w-100" src="/img/working.svg" height="100" />
          <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
            No Jobs Found
          </p>
          <p className="w-100  text-center mb-0">
            {data?.name
              ? "Drag jobs into this folder to get started."
              : "Add jobs above to get started."}
          </p>
        </div>
      ) : (
        <div className="file-group">
          {jobs.map((member) => {
            return (
              <div
                style={{
                  backgroundColor: selected.includes(member) ? "#e9ecef" : "",
                }}
                className="file-item pointer-hover"
                {...dragProps(member)}
              >
                <div
                  className="row no-gutters wd-100p"
                  style={{ userSelect: "none" }}
                >
                  <div className="col-12 col-sm-12 d-flex align-items-center">
                    {member.type === "App\\Models\\ResourceGroup" ? (
                      <i className="fa fa-folder-o" />
                    ) : null}{" "}
                    {member.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const EmptyFolders = ({ name }: { name: string }) => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center bg-white p-5 border">
      <img className="w-100" src="/img/files.svg" height="100" />
      <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
        No Folders Found for {name}.
      </p>
      <p className="w-100  text-center mb-0">
        Folders allow you to organise things more efficiently.
      </p>
      <p className="w-100  text-center mb-0">
        Click 'Add Folder' above to get started.
      </p>
    </div>
  );
};

const getNewPath = (path: string, uuid?: string): string => {
  const currentPath = path.split("/");

  currentPath.pop();

  return `${currentPath.join("/")}${uuid ? `/${uuid}` : ""}`;
};

export default ProjectJobFolders;
