import { Link, useParams } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import ChatModal from "../chat/ChatModal";
import UnpaidAmountBadges from "../clients/UnpaidAmountBadges";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import { AiOutlineSync } from "react-icons/ai";
import TemplateCopyButton from "./TemplateCopyButton";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { IoIosShareAlt } from "react-icons/io";
import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import deleteSwal from "../utils/deleteSwal";
import formError from "../utils/formError";

const ProjectHeader = ({ project }) => {
  const { number } = useParams();

  const externalLinks = project.external_links?.filter((link) => link.url);

  const { data: hazardReportQuestions } = useApi(`hazard-report-questions`, []);

  const getStatus = (status) => {
    switch (parseInt(status)) {
      case 0:
        return (
          <div className="badge mg-t-10 bg-info rounded-pill shadow">Open</div>
        );
      case 1:
        return (
          <div className="badge mg-t-10 bg-secondary rounded-pill shadow">
            Closed
          </div>
        );
    }
  };
  return (
    <DashboardHeader
      model={{
        type: "App\\Models\\Project",
        id: project.id,
      }}
      extraButtons={
        <>
          {hazardReportQuestions.length > 0 && (
            <Link to={`/hazard-reports/project/${project.id}/add`}>
              Safety Initiatives
            </Link>
          )}
          <ChatModal
            modelId={project.id}
            postUrl="projects/chat"
            modelType="App\Models\Project"
          />
        </>
      }
    >
      <div>
        <div className="d-flex mb-3">
          <div>
            <p className="mb-0 tx-16">{project.number}</p>
            <DashboardH3 className="mb-0 tx-24 text-dark">
              {project.name}
            </DashboardH3>
          </div>
          <div className="ms-auto space-x-2 d-flex align-items-center">
            <TemplateCopyButton project={project} />
            <ExternalLinks
              wrapperClass="d-md-flex d-none space-x-2"
              links={externalLinks}
            />
            <ShareToGroup
              shareableType="App\Models\Project"
              shareableId={project.id}
            />
          </div>
        </div>
      </div>

      <div className="d-flex">
        {project.internal ? (
          <DashboardSubTitle>Internal Project</DashboardSubTitle>
        ) : project.is_template ? null : (
          <div>
            <DashboardSubTitle>
              Client:{" "}
              <Link className="text-secondary" to={project.client.link}>
                {project.client.display_name}{" "}
              </Link>
              {project.client.hold && <Badge color="danger">On Hold</Badge>}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Address:{" "}
              {project.formatted_address != "Not Provided" ? (
                <a
                  target="__blank"
                  className="text-secondary"
                  href={`http://maps.google.com/maps?q=${project.formatted_address}`}
                >
                  {project.formatted_address}
                </a>
              ) : (
                project.formatted_address
              )}
            </DashboardSubTitle>
          </div>
        )}
      </div>

      <DashboardSubTitle>
        {project.tender && project.tender.job && (
          <div className="card-profile-position">
            Tender:{"   "}
            <Link to={project.tender.link}>{project.tender.name} </Link>
          </div>
        )}
      </DashboardSubTitle>
      <div className="d-flex align-items-end space-x-3">
        {project.is_template ? (
          <Badge color="primary" className="rounded-pill shadow mg-t-10">
            Template
          </Badge>
        ) : (
          <div className="d-flex space-x-3">
            {getStatus(project.status)}
            <UnpaidAmountBadges
              unpaid={project.total_unpaid}
              overdue={project.total_overdue}
              link={`/projects/${number}/invoices`}
            />
          </div>
        )}
      </div>
    </DashboardHeader>
  );
};

const ExternalLinks = ({ links }) => {
  const hasTeams = links?.filter((link) => link.name === "Teams").length > 0;

  const { number } = useParams();

  const { takeAction, loading } = useApi();

  if (!links) return null;

  if (links.length === 0) return null;

  return (
    <>
      {links.map((link) => (
        <>
          <a
            id={link.name}
            key={link.url}
            href={link.url}
            target="__blank"
            className="text-left"
            rel="noopener noreferrer"
          >
            <img
              height="25"
              style={{ width: "25px" }}
              src={link.icon}
              alt={link.name}
            />
          </a>
          <UncontrolledTooltip target={link.name}>
            Open in {link.name}
          </UncontrolledTooltip>
        </>
      ))}
      {hasTeams && (
        <>
          <Button
            onClick={() => {
              takeAction("show", `projects/${number}/teams-sync`).then(
                ({ data }) => {
                  location.href = data.data;
                },
              );
            }}
            color="link"
            className={`p-0 ${loading ? "spin" : ""}`}
            disabled={loading}
            id="sync-teams"
          >
            <AiOutlineSync className="tx-18" />
          </Button>
          <UncontrolledTooltip target="sync-teams">
            Sync in OneDrive
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

const ShareToGroup = ({ shareableType, shareableId }) => {
  const { user } = useAuth();

  const { data: currentShares, refreshData } = useApi(
    `group-shares?shareable_id=${shareableId}&shareable_type=${encodeURIComponent(
      shareableType,
    )}`,
    [],
  );

  const { toggle, modal } = useModal();

  const { takeAction, loading } = useApi();

  const shareToGroup = (values) => {
    return takeAction("store", `group-shares`, {
      ...values,
      shareable_id: shareableId,
      shareable_type: shareableType,
    })
      .then(() => {
        toast.success("Share added successfully");
        refreshData();
        toggle();
      })
      .catch(formError);
  };

  const deleteShare = (shareId) => {
    return deleteSwal().then(() => {
      return takeAction("destroy", `group-shares/${shareId}`)
        .then(() => {
          toast.success("Share deleted successfully");
          refreshData();
        })
        .catch(errorSwal);
    });
  };

  if (
    !user.active_organisation.group_members ||
    user.active_organisation.group_members?.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Button color="link" className="p-0" onClick={toggle}>
        <IoIosShareAlt className="tx-20 me-1 text-secondary" />
      </Button>
      <FormModal
        toggle={toggle}
        modal={modal}
        title="Share to Organisation"
        onSubmit={shareToGroup}
        form="ShareToGroup"
      >
        {currentShares.length > 0 && (
          <FormHeader>Currently Shared To</FormHeader>
        )}
        {currentShares.map((share) => {
          return (
            <div className="col-lg-6 form-group">
              <div className="bg-white p-2 rounded shadow-sm border d-flex align-items-center">
                <p className="mb-0 text-dark">
                  {share.organisation_shared_to.name}
                </p>
                <Button
                  onClick={() => deleteShare(share.uuid)}
                  outline
                  color="danger"
                  size="sm"
                  className="ms-auto"
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        })}
        <FormHeader>Share to new Organisation</FormHeader>
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            name="organisation_shared_to_id"
            label="Company"
            options={user.active_organisation.group_members
              .filter(
                (organisation) =>
                  !currentShares
                    .map((share) => share.organisation_shared_to_id)
                    .includes(organisation.id),
              )
              .map((organisation) => ({
                label: organisation.name,
                value: organisation.id,
              }))}
          />
        </div>
      </FormModal>
    </>
  );
};

export default ProjectHeader;
