import { SubscribedSku } from "@microsoft/microsoft-graph-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import LoadingOverlay from "../../utils/LoadingOverlay";
import { AssignedOfficeLicense, LicenseData } from "./UserMicrosoftDetails";
import { useAuth } from "../../../context/auth-context";
import deleteSwal from "../../utils/deleteSwal";
import { FaCheck } from "react-icons/fa";

const UserLicenseDetail = ({
  licenceData,
  licence,
  setLicences,
}: {
  licenceData: LicenseData;
  licence: SubscribedSku;
  setLicences: (licences: LicenseData) => void;
}) => {
  const { userId } = useParams<{ userId: string }>();
  const { user } = useAuth();
  const { takeAction, loading }: IUseApi<{}, { data: AssignedOfficeLicense }> =
    useApi();

  const currentUserLicence = licenceData?.user_licences.find(
    (userLicence) => userLicence.skuId === licence.skuId,
  );

  const addLicence = (licence: SubscribedSku) => {
    return takeAction(
      "store",
      `microsoft/licences/users/${userId}/${licence.skuId}`,
      {
        licence_id: licence.skuId,
      },
    )
      .then(({ data }) => {
        setLicences({
          organisation_licences: licenceData?.organisation_licences ?? [],
          user_licences: [
            ...(licenceData?.user_licences ?? []),
            {
              skuId: licence.skuId,
              skuPartNumber: licence.skuPartNumber,
            },
          ],
        });
        toast.success("Licence added");
      })
      .catch(errorSwal);
  };

  const deleteLicence = (licence: SubscribedSku) => {
    return takeAction(
      "destroy",
      `microsoft/licences/users/${userId}/${licence.skuId}`,
    )
      .then(() => {
        setLicences({
          organisation_licences: licenceData?.organisation_licences ?? [],
          user_licences:
            licenceData?.user_licences.filter(
              (l) => l.skuId !== licence.skuId,
            ) ?? [],
        });
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-6 form-group">
      <button
        type="button"
        onClick={() =>
          currentUserLicence
            ? deleteSwal().then(() => deleteLicence(currentUserLicence))
            : addLicence(licence)
        }
        className={`${
          currentUserLicence ? "bg-light" : "bg-white"
        } p-3 border shadow-sm rounded w-100 d-flex position-relative`}
      >
        <LoadingOverlay loading={loading} />
        <div className="text-start w-100">
          <div>
            <p className="mb-0" style={{ maxWidth: "80%" }}>
              {/** @ts-ignore */}
              {licence.displayName}
            </p>
            <small className="text-secondary">{licence.skuPartNumber}</small>
          </div>

          {currentUserLicence && (
            <small className="text-secondary">License assigned</small>
          )}
        </div>
        {currentUserLicence && (
          <FaCheck className="ms-auto tx-18 text-success" />
        )}
      </button>
    </div>
  );
};

export default UserLicenseDetail;
