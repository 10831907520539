import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useAuth } from "../../context/auth-context";

const HelpDropdown = () => {
  const { user } = useAuth();

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle className="header-notification me-1" color="link">
          <i className="icon ion-help tx-20"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.thebossapp.com.au"
          >
            Documentation
          </DropdownItem>
          <DropdownItem href="mailto:info@thebossapp.com.au">
            Contact Support
          </DropdownItem>
          {user?.active_organisation.beta && (
            <DropdownItem>
              <Link to="/support-requests">Support Requests</Link>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default HelpDropdown;
