import { useState } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector } from "redux-form";
import AddressFields from "../addresses/AddressFields";
import PurchaseItems from "../purchaseItems/PurchaseItems";
import FileInput from "../upload/FileInput";
import buttonGroup from "../utils/buttonGroup";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import ReceiptField from "./ReceiptField";
import dayjs from "dayjs";
import SelectInput from "../form/SelectInput";
import PurchaseOrderEquipmentFields from "../purchaseOrders/PurchaseOrderEquipmentFields";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import MentionField from "../form/MentionField";
import { draftField } from "../form/formatters";

const postalFields = ["line_1", "line_2", "state", "postcode", "suburb"];

export const formatSuppliers = (suppliers) => {
  return suppliers.map((supplier) => ({
    label: `${supplier.display_name}${
      supplier.disabled ? ` ${supplier.disabled}` : ""
    }`,
    value: supplier.id,
    disabled: supplier.disabled,
  }));
};

const Fields = (props) => {
  const [selectedSite, setSelectedSite] = useState({});
  const isPurchaseOrder = props.purchaseOrder;

  const { user } = useAuth();

  const { purchaseItems } = props;

  const changeTrackingSite = (val) => {
    if (!val) return;
    setSelectedSite(val);
    props.change("phone", val.phone);
    props.change("street_or_postal", "");
  };

  const changeStreetOrPostal = (val) => {
    if (!selectedSite) {
      return;
    }

    postalFields.forEach((field) => {
      if (!val) {
        props.change(field, "");
        return;
      }
      props.change(
        field,
        val === "street"
          ? selectedSite[field]
          : selectedSite[`postal_${field}`],
      );
    });
  };

  const buttonOptions = [
    {
      label: "Postal",
      value: "postal",
    },
    {
      label: "Street Address",
      value: "street",
    },
  ];

  //2 is fixed asset
  const hasFixedAsset = purchaseItems?.some(
    (item) => item.chart_of_accounts_type == 2,
  );

  return (
    <>
      <div className={`col-lg-${isPurchaseOrder ? "12" : "9"} form-group`}>
        <Field
          label="GST Options"
          component={SelectInputAsync}
          name="includes_gst"
          url="/gst-options"
          required
          validate={required}
          isSearchable={false}
        />
      </div>
      {!props.purchaseOrder && !props.testSamplePurchaseOrder && (
        <div className="col-lg-3 form-group">
          <Field
            label="Refund?"
            component={renderToggleInput}
            name="is_refund"
          />
        </div>
      )}
      {isPurchaseOrder && (
        <div className="col-12 form-group">
          <Field
            component={SelectInputAsync}
            name="supplier_id"
            label="Supplier"
            url="/suppliers"
            formatData={(data) => formatSuppliers(data.data)}
            required
            validate={required}
          />
        </div>
      )}

      {!isPurchaseOrder && props.file && <ReceiptField {...props} />}

      {!isPurchaseOrder && (
        <>
          <div
            className={`col-lg-${props.purchaseOrder ? "4" : "12"} form-group`}
          >
            <Field
              component={renderField}
              type="date"
              required
              validate={required}
              label="Date"
              name="date"
              extraProps={{
                max: dayjs().format("YYYY-MM-DD"),
              }}
            />
          </div>

          {props.initialValues?.uuid ? (
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInput}
                name="project_id"
                url="/custom-fields/projects"
                label="Project"
                formatData={(data) => {
                  return data.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }));
                }}
              />
            </div>
          ) : null}
          <div className="col-lg-6 form-group">
            <Field
              component={renderField}
              name="supplier_name"
              label="Supplier Name"
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={renderField}
              name="reference_number"
              label="Reference Number"
            />
          </div>
        </>
      )}
      {isPurchaseOrder && (
        <>
          <div className="col-lg-4 form-group">
            <Field
              component={renderField}
              name="delivery_date"
              type="date"
              label="Expected Delivery Date"
            />
          </div>

          <div className="col-lg-4 form-group">
            <Field
              component={renderField}
              name="quote_number"
              label="Quote Number"
            />
          </div>

          {!props?.initialValues?.uuid && (
            <div className="col-lg-4">
              <Field
                component={renderToggleInput}
                name="site_order"
                label="Site Order"
              />
            </div>
          )}

          {props.file && (
            <div className="col-lg-12 mb-3">
              <Field component={FileInput} name="file" label="Attach Quote" />
            </div>
          )}
          <FormHeader>Delivery Details</FormHeader>
          <div className="col-lg-6 form-group">
            <Field
              component={SelectInputAsync}
              name="tracking_site"
              label="Auto Fill Delivery Address from Tracking Site"
              onChangeValue={changeTrackingSite}
              url="/trackingsites"
              formatData={({ data }) =>
                data.map(({ trackingsite }) => {
                  return {
                    label: trackingsite.name,
                    value: trackingsite.uuid,
                    ...trackingsite,
                  };
                })
              }
            />
          </div>
          <div className="col-lg-6 d-flex align-items-end form-group">
            <Field
              component={buttonGroup}
              name="street_or_postal"
              groupClass="w-100"
              buttonClass="w-100"
              options={buttonOptions}
              onClick={changeStreetOrPostal}
            />
          </div>
          <AddressFields hideStreetTitle {...props} validate={false} />
          <div className="col-12 form-group">
            <Field
              component={renderField}
              name="phone"
              label="Delivery Phone"
            />
          </div>
          <div className="col-12 form-group">
            <Field
              component={renderField}
              name="delivery_instructions"
              label="Delivery Instructions"
              textarea
            />
          </div>
          {props?.purchaseOrder?.archived_at && (
            <div className="col-12 form-group">
              <Field
                component={renderField}
                name="archive_reason"
                label="Archive Reason"
                textarea
                required
                validate={required}
              />
            </div>
          )}
        </>
      )}
      {!props.item?.file?.link &&
        !props.item?.is_approved &&
        !props.item?.approval_action &&
        props.creditCard && (
          <div className="col-12 form-group">
            <Field
              component={renderToggleInput}
              name="request_exemption"
              label="Request Exemption"
            />
            <small className="d-block">
              If this is checked, you will not be required to upload a receipt.{" "}
              <br />
              This must first be approved by the accounting group leader.
            </small>
          </div>
        )}
      {props.requestExemption && (
        <div className="form-group col-12">
          <Field
            name="exemption_reason"
            label="Exemption Reason"
            validate={required}
            required
            component={MentionField}
            spellCheck={true}
            {...draftField}
          />
        </div>
      )}
      <FieldArray
        isTestPurchaseOrder={props.testRequestId}
        fromEditPage={props.editPage}
        component={PurchaseItems}
        name="purchase_items"
        {...props}
      />
      {hasFixedAsset &&
        !props.creditCard &&
        user.active_organisation.integration_types.includes(
          IntegrationType.Accounting,
        ) && (
          <>
            <FormHeader
              extra={
                <p className="mb-0">
                  At least on piece of equipment is required for fixed asset
                  purchases
                </p>
              }
            >
              Equipment
            </FormHeader>
            <FieldArray
              change={props.change}
              component={PurchaseOrderEquipmentFields}
              initialValues={props.initialValues}
              name="equipment"
            />
          </>
        )}
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    siteOrder: selector(state, "site_order"),
    testRequestId: selector(state, "test_request_id"),
    purchaseItems: selector(state, "purchase_items"),
    requestExemption: selector(state, "request_exemption"),
  };
};

export default connect(mapStateToProps, {})(Fields);
