import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import HOW_CLIENTS_FOUND from "../../data/how_clients_found";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import AddMultiple from "../form/AddMultiple";
import SelectInput from "../form/SelectInput";
import AbnField from "../utils/AbnField";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const DetailForm = (props) => {
  const {
    handleSubmit,
    setClient,
    client,
    abn_holder,
    contact_email,
    accounts_email,
  } = props;

  const { takeAction } = useApi("client-types", []);

  const { user } = useAuth();

  const submit = (values) => {
    return takeAction("update", `organisation-clients/${client.uuid}`, values)
      .then(({ data }) => {
        setClient(data.data);
        return toast.success("Client updated");
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        {user.is_admin && (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="abn_holder"
                label="ABN Holder"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderField}
                name="name"
                label="Name"
                readOnly={abn_holder}
              />
            </div>

            {abn_holder && (
              <>
                <div className="col-lg-6 form-group">
                  <AbnField {...props} validate={false} />
                </div>

                <div className="col-lg-6 form-group">
                  <Field
                    component={renderField}
                    name="trading_name"
                    label="Trading Name"
                  />
                </div>

                <div className="col-lg-6 form-group">
                  <div className="d-flex align-items-center mb-2">
                    <label className="tx-inverse tx-semibold mb-0">
                      Account Email/s
                    </label>
                    <div className="d-flex ms-auto align-items-center">
                      <CopyToClipboard
                        options={{ format: "text/plain" }}
                        text={accounts_email?.join(",")}
                        onCopy={() => toast.success("Email Copied")}
                      >
                        <button
                          className="border-0 bg-transparent text-secondary"
                          type="button"
                        >
                          Copy
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <Field component={AddMultiple} name="accounts_email" />
                </div>
              </>
            )}
            <div className="col-12 form-group">
              <Field
                component={renderField}
                textarea
                name="description"
                label="Description"
              />
            </div>

            <div className="col-lg-4 form-group">
              <div className="d-flex align-items-center mb-2">
                <label className="tx-inverse tx-semibold mb-0">
                  Contact Email/s <span className="tx-danger"> *</span>
                </label>
                <div className="d-flex ms-auto align-items-center">
                  <CopyToClipboard
                    options={{ format: "text/plain" }}
                    text={contact_email?.join(",")}
                    onCopy={() => toast.success("Email Copied")}
                  >
                    <button
                      className="border-0 bg-transparent text-secondary"
                      type="button"
                    >
                      Copy
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <Field
                component={AddMultiple}
                name="contact_email"
                validate={required}
                required
                hideRequired
              />
              <small>
                When sending notifications to this supplier, the first email
                address in this list will be the address the email is sent to.
              </small>
            </div>

            <div className="col-lg-4 form-group">
              <Field
                component={renderField}
                name="contact_telephone"
                label="Contact Phone"
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={renderField}
                name="accounts_telephone"
                label="Accounts Phone"
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                multiple
                label="Client Winners"
                component={SelectInputAsync}
                name="client_winners"
                url="/users/staff"
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInputAsync}
                name="how_client_found"
                options={HOW_CLIENTS_FOUND}
                label="How Client Found"
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                name="client_types"
                url="client-types"
                label="Business Activities"
                required
                isMulti
                formatData={(data) => {
                  return data.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }));
                }}
              />
            </div>
            <AddressFields postal {...props} validate={false} />
          </>
        )}

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "ClientDetails" });

const selector = formValueSelector("ClientDetails");

const mapStateToProps = (state) => ({
  invoice_terms: selector(state, "invoice_terms"),
  has_account: selector(state, "has_account"),
  abn_holder: selector(state, "abn_holder"),
  contact_email: selector(state, "contact_email"),
  accounts_email: selector(state, "accounts_email"),
});

export default connect(mapStateToProps, {})(form(DetailForm));
