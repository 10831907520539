import { FieldArray, InjectedFormProps } from "redux-form";
import FormModal from "../utils/FormModal";
import { Job } from "../jobs/jobTypes";
import AllowanceFields from "./AllowanceFields";

const AllowanceModal = ({
  job,
  allowances,
  toggle,
  modal,
  onSubmit,
}: {
  allowances: any[];
  toggle: Function;
  modal: boolean;
  job: Job;
  onSubmit: Function;
}) => {
  const initialValues = {
    allowances: allowances.map((allowance) => ({
      ...allowance,
      include: true,
    })),
  };

  return (
    <FormModal
      title={`Add Allowances for ${job.name}`}
      toggle={toggle}
      modal={modal}
      form={`allowance-${job.id}`}
      initialValues={initialValues}
      modalBodyClass="bg-gray-300"
      onSubmit={onSubmit}
    >
      {({ change }: InjectedFormProps) => {
        return (
          <FieldArray
            name="allowances"
            component={AllowanceFields}
            change={change}
          />
        );
      }}
    </FormModal>
  );
};

export default AllowanceModal;
