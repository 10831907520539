import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import formatMoney from "../utils/formatMoney";

const UnpaidAmountBadges = ({ unpaid, overdue, link }) => {
  const { user } = useAuth();
  const isPositionAdmin = user.is_position_admin;

  if (!isPositionAdmin) {
    return null;
  }

  return (
    <div className="align-self-end d-flex space-x-1">
      {overdue !== 0 && (
        <Link
          to={{
            pathname: link,
            state: {
              filter: "overdue",
            },
          }}
        >
          <Badge color="danger" className="rounded-pill shadow">
            ${formatMoney(overdue)} overdue.
          </Badge>
        </Link>
      )}
      {unpaid !== 0 && (
        <Link
          to={{
            pathname: link,
            state: {
              filter: "unpaid",
            },
          }}
        >
          <Badge color="info" className="rounded-pill shadow">
            ${formatMoney(unpaid)} unpaid.
          </Badge>
        </Link>
      )}
    </div>
  );
};

export default UnpaidAmountBadges;
