import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import errorSwal from "../utils/errorSwal";
import GeneralForm from "./GeneralForm";
import useApi from "../api/useApi";
import { useAuth } from "../../context/auth-context";
import SharedJobProfile from "./SharedJobProfile";

const ProfileForm = (props) => {
  const { handleSubmit, setJob, job } = props;
  const { takeAction } = useApi();

  const { user } = useAuth();

  const onSubmit = (values) => {
    return takeAction("update", `project-jobs/${values.uuid}`, values)
      .then(({ data }) => {
        setJob(data.data);
        toast.success(`${data.data.name} updated successfully`);
      })
      .catch(errorSwal);
  };

  if (user.active_organisation.id !== job.organisation.id) {
    return <SharedJobProfile job={job} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GeneralForm {...props} />
    </form>
  );
};

const form = reduxForm({
  form: "JobProfileForm",
});

export default form(ProfileForm);
