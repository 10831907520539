import { reduxForm, FieldArray, Field } from "redux-form";
import { toast } from "react-toastify";
import axios from "../api/api";
import PaymentFieldArray from "../recurringPurchases/PaymentFieldArray";
import SubmitButton from "../utils/SubmitButton";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";

const Edit = (props) => {
  const { handleSubmit, initialValues, payment, setPayment, isAccounting } =
    props;

  const onSubmit = (values) => {
    return axios
      .put(`/recurring-payment-purchases/${payment.uuid}`, values)
      .then(({ data }) => {
        setPayment(data.data);
        toast.success("Purchase updated.");
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="invoice.number"
            validate={required}
            required
            label="Invoice Number"
          />
        </div>
        <FieldArray
          component={PaymentFieldArray}
          recurringPurchase={initialValues.recurring_payment}
          name="items"
          canAdd={payment.approval_action && isAccounting}
          {...props}
        />

        {(!payment.approval_action &&
          payment.approval_status?.status !== "Marked Redo") ||
        !isAccounting ? null : (
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({ form: "RecurringPayment" });

export default form(Edit);
