import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import LIKELIHOOD_OF_SUCCESS from "../../data/likelihood_of_success";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import { money, percentage } from "../form/formatters";
import FormErrorAlert from "../form/FormErrorAlert";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import formError from "../utils/formError";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import MarkAsSubmittedButton from "./MarkAsSubmittedButton";

const Edit = (props) => {
  const { handleSubmit, tender, setTender, initialValues, change } = props;
  const { uuid } = useParams();
  const { user } = useAuth();

  const [newClient, setNewClient] = useState(!tender.client);

  const { takeAction } = useApi();
  const { upload } = useUploadCustomFields(
    `organisations/${user.active_organisation.uuid}/tenders/${uuid}`,
  );

  const submit = (values) => {
    return upload(values)
      .then((values) => takeAction("update", `tenders/${tender.uuid}`, values))
      .then(({ data }) => {
        toast.success("Tender edited successfully");
        setTender(data.data);
      })
      .catch(formError);
  };

  return (
    <>
      <MarkAsSubmittedButton {...props} />
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <FormErrorAlert error={props.error} />
          <div className="form-group col-lg-6">
            <Field
              name="type_id"
              component={SelectInputAsync}
              url="/tender-types"
              required
              validate={required}
              label="Type"
              formatData={(data) =>
                data.data.map((type) => ({ label: type.name, value: type.id }))
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <div className="d-flex align-items-center mb-2">
              <label className="tx-inverse tx-semibold mb-0">Client</label>
              <div className="d-flex ms-auto align-items-center">
                <Toggle
                  checked={newClient}
                  onChange={() => {
                    setNewClient(!newClient);
                    change("tender.client_name", null);
                    change("tender.client_id", null);
                  }}
                  className="toggle-sm"
                  icons={false}
                />
                <span className="tx-sm ms-1">New Client</span>
              </div>
            </div>
            <Field
              name={newClient ? "client_name" : "client_id"}
              component={newClient ? renderField : SelectInputAsync}
              url="/organisations/clients"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={renderField}
              name="project_name"
              label="Project Name"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              component={SelectInputAsync}
              name="job.project_manager"
              label="Tender Manager"
              url="/users/staff"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="job.users"
              label="Staff"
              multiple="true"
              component={SelectInputAsync}
              url="/users/staff"
              placeholder="Staff"
            />
            <small>
              Select Staff from Your Organisation that will be working on this
              job
            </small>
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={renderToggleInput}
              name="confidential"
              label="Confidential"
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={renderField}
              name="comments"
              label="Comments"
              textarea
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="total_price"
              label="Total Tender Price"
              required
              validate={required}
              {...money}
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              validate={required}
              required
              name="subcontractor_expenses"
              label="Disbursements"
              information="Sub-contract payments to an external party. (non marked up cost) e.g. sub-consultant, external equipment hire"
              {...money}
            />
          </div>

          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="job.scheduled_start_date"
              label="Tender Start"
              type="date"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="job.scheduled_finish_date"
              label="Tender Due"
              type="date"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              name="work_start_date"
              type="date"
              component={renderField}
              required
              validate={required}
              label="Expected Start Date of Work"
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              name="work_finish_date"
              type="date"
              component={renderField}
              required
              validate={required}
              label="Expected Finish Date of Work"
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              component={SelectInputAsync}
              name="job.branch_id"
              label="Branch"
              required
              url="/organisationbranches/branches"
              validate={required}
              hideDisabled={true}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              component={SelectInputAsync}
              name="likelihood_of_success"
              label="Likelihood of Success"
              required
              options={LIKELIHOOD_OF_SUCCESS}
              validate={required}
              order={false}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="probability_of_project_starting"
              component={renderField}
              label="Probability of Work Commencing"
              required
              validate={required}
              {...percentage}
            />
            <small>
              The percentage chance that the project/job will actually go ahead.
            </small>
          </div>

          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="job.estimated_hours"
              label="Estimated Hours to Complete Tender"
              type="number"
              required
              validate={required}
              extraProps={{
                step: "0.01",
              }}
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              name="industry_id"
              component={SelectInputAsync}
              required
              label="Industry"
              validate={required}
              url={`/react-select?select=id,name&label=name&value=id&model=${encodeURIComponent(
                "App\\Models\\Industry",
              )}`}
            />
          </div>

          <AddressFields {...props} />

          {initialValues?.custom_form?.length > 0 && (
            <>
              <FormHeader>{initialValues.type.name} Details</FormHeader>
              <CustomForm customForm={initialValues.custom_form} {...props} />
            </>
          )}

          <div className="form-group col-lg-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm({
  form: "EditTender",
});

export default form(Edit);
