import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DropdownItem } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import useS3Uploader from "../hooks/useS3Uploader";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import DeleteModal from "./DeleteModal";
import documentProps from "./documentProps";
import DownloadLogModal from "./DownloadLogModal";
import FileListItem from "./FileListItem";
import VersionModal from "./VersionModal";
import CreateModal from "./CreateModal";

const Item = ({
  document,
  setSelectedDocument,
  setFilledTemplate,
  togglePreview,
  setFolder,
  folder,
  revisionActive,
  setRevisionActive,
  onSuccess,
}) => {
  const { modal: createModal, toggle: toggleCreate } = useModal();
  const { modal: deleteModal, toggle: toggleDelete } = useModal();
  const { modal: versionModal, toggle: toggleVersion } = useModal();
  const { modal: downloadLogModal, toggle: toggleDownloadLogVersion } =
    useModal();
  const { user } = useAuth();

  const { upload, uploadPercent } = useS3Uploader();

  const { takeAction } = useApi();

  const toggleSetVersion = () => {
    toggleVersion();
    setRevisionActive(!revisionActive);
  };

  const isAdmin = user.is_admin;

  const isActiveOrganisation =
    user.active_organisation.id === document.organisation_id;

  const deleteFile = (values) => {
    takeAction("store", `documents/${document.uuid}/deleted`, values)
      .then(() => {
        toggleDelete();
        toast.success(`${document.name} deleted.`);
        setFolder({
          ...folder,
          children: folder.children.filter((c) => c.uuid !== document.uuid),
        });
      })
      .catch(errorSwal);
  };

  const deleteFolder = () => {
    deleteSwal(document.name)
      .then(() => {
        return takeAction("destroy", `folders/${document.uuid}`);
      })
      .then(() => {
        toast.success(`${document.name} deleted.`);
        setFolder({
          ...folder,
          children: folder.children.filter((c) => c.uuid !== document.uuid),
        });
      })
      .catch(errorSwal);
  };

  const uploadRevision = (values) =>
    upload(
      values.revision,
      `organisations/${user.active_organisation.uuid}/documents`,
      (data) => {
        return takeAction("store", `documents/${document.uuid}/versions`, {
          ...data,
          reason: values.reason,
        })
          .then(({ data }) => {
            toast.success(`${document.name} revision added.`);
            setFolder({
              ...folder,
              children: folder.children.map((child) =>
                child.uuid !== document.uuid ? child : data.data,
              ),
            });
          })
          .then(toggleSetVersion)
          .catch(errorSwal);
      },
    );

  const manageDownload = () => {
    return document.is_templatable
      ? takeAction("store", `templates/${document.uuid}/download`, {
          model: "App\\Models\\Document",
          id: document.id,
          format: "Word",
        })
          .then(({ data }) => {
            window.location.replace(data?.data);
          })
          .catch(errorSwal)
      : takeAction("store", "document-downloads", {
          document_type: "App\\Models\\Document",
          document_id: document.id,
        })
          .then(() => window.location.replace(document.link))
          .catch(errorSwal);
  };

  const manageSharing = (url) => {
    takeAction("update", `${url}/${document.uuid}`, {
      name: document.name,
      is_shared: !document.is_shared,
    }).then(() => {
      onSuccess();
    });
  };

  const manageTemplate = () => {
    return [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(document.mime_type)
      ? takeAction("update", `documents/${document.uuid}`, {
          name: document.name,
          is_shared: document.is_shared,
          is_templatable: !document.is_templatable,
        })
          .then(() => onSuccess())
          .catch(errorSwal)
      : toast.warning(
          `Only Office Word(.docx) and Excel(.xlsx) files are allowed.`,
        );
  };

  return (
    <>
      <Link
        onClick={(e) => {
          if (!document.is_folder) {
            e.preventDefault();
            setSelectedDocument(document);
            togglePreview();
          }
          document?.is_templatable && setFilledTemplate(document);
        }}
        to={(location) =>
          document.is_folder ? `/documents/${document.uuid}` : location.pathname
        }
      >
        <FileListItem
          document={document}
          more={
            <>
              {document.is_folder && isAdmin && isActiveOrganisation ? (
                <>
                  {document.syncable && (
                    <DropdownItem onClick={() => manageSharing("folders")}>
                      {document.is_shared ? "Stop Sharing" : "Share"}
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={deleteFolder}>Delete</DropdownItem>
                  {user.hasAccessTo("App\\Models\\Document", "create_add_version") && (
                    <DropdownItem onClick={toggleCreate}>Create Add Version</DropdownItem>
                  )}
                </>
              ) : (
                <>
                  <DropdownItem onClick={manageDownload}>
                    Download File
                  </DropdownItem>
                  {!document.is_folder && isActiveOrganisation && isAdmin && (
                    <DropdownItem onClick={() => manageTemplate()}>
                      {document?.is_templatable ? "Remove" : "Mark "} as
                      Template
                    </DropdownItem>
                  )}
                  {isActiveOrganisation && (
                    <>
                      {document.syncable && (
                        <DropdownItem
                          onClick={() => manageSharing("documents")}
                        >
                          {document.is_shared ? "Stop Sharing" : "Share"}
                        </DropdownItem>
                      )}
                      {user.hasAccessTo(
                        "App\\Models\\Document",
                        "add_version",
                      ) && (
                        <DropdownItem onClick={toggleSetVersion}>
                          View/Add Versions
                        </DropdownItem>
                      )}
                      {user.hasAccessTo(
                        "App\\Models\\Document",
                        "view_download_log",
                      ) && (
                        <DropdownItem onClick={toggleDownloadLogVersion}>
                          View Download Log
                        </DropdownItem>
                      )}
                      {user.hasAccessTo("App\\Models\\Document", "delete") && (
                        <DropdownItem onClick={toggleDelete}>
                          Delete
                        </DropdownItem>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          }
        />
      </Link>
      <DeleteModal
        modal={deleteModal}
        toggle={toggleDelete}
        title={document.name}
        onSubmit={deleteFile}
        form={`DeleteDocument${document.uuid}`}
      />
      <VersionModal
        modal={versionModal}
        toggle={toggleSetVersion}
        document={document}
        onSubmit={uploadRevision}
        form={`DocumentVersion${document.uuid}`}
        percent={uploadPercent}
      />
      <DownloadLogModal
        modal={downloadLogModal}
        toggle={toggleDownloadLogVersion}
        document={document}
        document_type={"App\\Models\\Document"}
      />
      <CreateModal
        modal={createModal}
        toggle={toggleCreate}
        title={document.name}
        form={`CreateDocument${document.uuid}`}
        folder={document}
        setFolder={setFolder}
      />
    </>
  );
};

Item.propTypes = {
  setSelectedDocument: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
  ...documentProps,
  setFolder: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_folder: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
};

export default Item;
