const moisture_specs = [
  {
    label: "-",
    value: "-",
  },
  {
    label: "+/-2.0% of OMC",
    value: "+/-2.0% of OMC",
  },
  {
    label: "+/-3.0% of OMC",
    value: "+/-3.0% of OMC",
  },
  {
    label: "+/-4.0% of OMC",
    value: "+/-4.0% of OMC",
  },
  {
    label: "0% to +4%",
    value: "0% - +4%",
  },
  {
    label: "-1.0% to +3.0% of OMC",
    value: "-1.0% - +3.0% of OMC",
  },
  {
    label: "-1.0% to -2.5% of OMC",
    value: "-1.0% -2.5% of OMC",
  },
  {
    label: "-2.0% to +1.0% of OMC",
    value: "-2.0% - +1.0% of OMC",
  },
  {
    label: "-0.5% to +1.5% of OMC",
    value: "-0.5% - +1.5% of OMC",
  },
  {
    label: "-1.0% to +1.0% of OMC",
    value: "-1.0% - +1.0% of OMC",
  },
  {
    label: "-2.0% to +3.0% of OMC",
    value: "-2.0% - +3.0% of OMC",
  },
  {
    label: "-4.0% to +1.0% of OMC",
    value: "-4.0% - +1.0% of OMC",
  },
  {
    label: "-1% to +2% of OMC",
    value: "-1% - +2% of OMC",
  },
  {
    label: "50% to 90% of OMC",
    value: "50% - 90% of OMC",
  },
  {
    label: "60% to 90% of OMC",
    value: "60% - 90% of OMC",
  },
  {
    label: "60% to 110% of OMC",
    value: "60% - 110% of OMC",
  },
  {
    label: "80% to 100% of OMC",
    value: "80% - 100% of OMC",
  },
  {
    label: "60% to 80% of OMC",
    value: "60% - 80% of OMC",
  },
  {
    label: "70% to 100% of OMC",
    value: "70% - 100% of OMC",
  },
  {
    label: "-3.0% to +1.0% of OMC",
    value: "-3.0% - +1.0% of OMC",
  },
  {
    label: "-3% to -1.5% of OMC",
    value: "-3% to -1.5% of OMC",
  },
  {
    label: "-1.5% to +3% of OMC",
    value: "-1.5% to +3% of OMC",
  },
  {
    label: "-3% to +2% of OMC",
    value: "-3% to +2% of OMC",
  },
  {
    label: "-4% to +0% of OMC",
    value: "-4% to +0% of OMC",
  },
  {
    label: "-2.5% to +0% of OMC",
    value: "-2.5% to +0% of OMC",
  },
  {
    label: "0% to +3% OMC",
    value: "0% to +3% OMC",
  },
  {
    label: "90% - 110% of OMC",
    value: "90% - 110% of OMC",
  },
  {
    label: "85% - 105% of OMC",
    value: "85% - 105% of OMC",
  },
  {
    label: "-2.0% to +0% of OMC",
    value: "-2.0% to +0% of OMC",
  },
];

export default moisture_specs;
