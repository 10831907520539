import { useState } from "react";
import ProjectTable from "./ProjectTable";
import Add from "./Add";
import DocsModal from "../utils/DocsModal";
import PaginatedList from "../pagination/PaginatedList";
import useProjectInvoices from "./hooks/useProjectInvoices";
import { Invoice } from "./invoiceTypes";
import { Link, useLocation } from "react-router-dom";

type FilterType = "overdue" | "unpaid";

interface LocationState {
  filter?: FilterType;
}

const Page = (props : any) => {
  const { state } = useLocation<LocationState>();
  const [filter, setFilter] = useState<string | undefined>(state?.filter);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/adding-an-invoice/" />
      <Add {...props} />
      <PaginatedList
        listName="ProjectInvoicesList"
        indexHookArguments={[props.project.uuid, filter]}
        indexHook={useProjectInvoices}
        originalFilters={[]}
        list={({ data }: { data?: Invoice[] }) => (
          <>
            {data && <ProjectTable data={data} />}
          </>
        )}
      />
    </>
  );
};

export default Page;
